var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { InputAdornment, IconButton, Stack, TextField, } from '@mui/material';
import { WarningRounded } from '@mui/icons-material';
import classnames from 'classnames';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './TPTextInput.module.css';
var TPTextInput = function (_a) {
    var _b, _c;
    var _d, _e, _f, _g, _h, _j;
    var warning = _a.warning, error = _a.error, type = _a.type, _k = _a.variant, variant = _k === void 0 ? 'outlined' : _k, _l = _a.lengthStatus, lengthStatus = _l === void 0 ? true : _l, rest = __rest(_a, ["warning", "error", "type", "variant", "lengthStatus"]);
    var _m = useState(false), showPassword = _m[0], setShowPassword = _m[1];
    var inputType = type === 'password' && showPassword ? 'text' : type;
    var handleClickShowPassword = function () {
        setShowPassword(function (prev) { return !prev; });
    };
    function validationInput(input) {
        var regex = /^[a-zA-Z0-9\s,.-]+$/;
        if (lengthStatus && input.length >= 100) {
            console.log('enter');
            return false;
        }
        return regex.test(input);
    }
    var handleChange = function (event) {
        var _a;
        var value = event.target.value;
        if (type === 'email' || value === '' || validationInput(value)) {
            (_a = rest.onChange) === null || _a === void 0 ? void 0 : _a.call(rest, event);
        }
    };
    var getAdornment = function () {
        var _a;
        var isPassword = type === 'password';
        var showWarningIcon = warning || error;
        if (!isPassword && !showWarningIcon)
            return null;
        return (_jsx(InputAdornment, __assign({ position: 'end' }, { children: _jsxs(Stack, __assign({ direction: 'row', alignItems: 'center' }, { children: [showWarningIcon && (_jsx(WarningRounded, { className: classnames((_a = {},
                            _a[styles.errorIcon] = error,
                            _a[styles.warningIcon] = warning && !error,
                            _a)) })), isPassword && (_jsx(IconButton, __assign({ "aria-label": 'toggle password visibility', onClick: handleClickShowPassword, disableRipple: true }, { children: showPassword ? (_jsx(VisibilityIcon, { className: styles.passwordIcon })) : (_jsx(VisibilityOffIcon, { className: styles.passwordIcon })) })))] })) })));
    };
    return (_jsx(TextField, __assign({}, rest, { fullWidth: true, variant: variant, InputLabelProps: {
            shrink: true,
            className: classnames(styles.textInputLabel, (_d = rest.InputLabelProps) === null || _d === void 0 ? void 0 : _d.className),
        }, inputProps: __assign({}, rest.inputProps), InputProps: {
            endAdornment: (_f = (_e = rest.InputProps) === null || _e === void 0 ? void 0 : _e.endAdornment) !== null && _f !== void 0 ? _f : getAdornment(),
            readOnly: (_g = rest.InputProps) === null || _g === void 0 ? void 0 : _g.readOnly,
            className: classnames(styles.textInput, (_b = {}, _b[styles.warning] = warning, _b[styles.error] = error, _b), (_h = rest.InputProps) === null || _h === void 0 ? void 0 : _h.className, rest.multiline ? styles.multiline : ''),
        }, className: classnames(styles.TextInputField, rest.className), FormHelperTextProps: {
            className: classnames(styles.textInputHelperText, (_c = {}, _c[styles.warning] = warning, _c[styles.error] = error, _c), (_j = rest.FormHelperTextProps) === null || _j === void 0 ? void 0 : _j.className),
        }, type: inputType })));
};
export default TPTextInput;
