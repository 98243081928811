var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { MenuItem, Select, FormControl, InputLabel, } from '@mui/material';
import './TPDropdown.scss';
import TPSvgIcon from '../svg-icon/TPSvgIcon';
import { fontSizeDynamic } from '../fontsizeutils/fontSizeUtils';
var TPDropdown = function (props) {
    var options = props.options, id = props.id, defaultSelectedOption = props.defaultSelectedOption, name = props.name, label = props.label, onChange = props.onChange, placeholder = props.placeholder, placeholderClassName = props.placeholderClassName, onOpen = props.onOpen, onClose = props.onClose, IconComponent = props.IconComponent, sx = props.sx, placeholderStyle = props.placeholderStyle, otherProps = __rest(props, ["options", "id", "defaultSelectedOption", "name", "label", "onChange", "placeholder", "placeholderClassName", "onOpen", "onClose", "IconComponent", "sx", "placeholderStyle"]);
    var _a = useState(false), selectOpen = _a[0], setSelectOpen = _a[1];
    var handleSelectChange = function (e) {
        onChange(e);
    };
    var handleOpen = function () {
        setSelectOpen(true);
        if (onOpen) {
            onOpen(true);
        }
    };
    var handleClose = function () {
        setSelectOpen(false);
        if (onClose) {
            onClose(false);
        }
    };
    return (_jsxs(FormControl, __assign({ className: 'formLabel', fullWidth: true, variant: 'standard', sx: { fontSize: fontSizeDynamic(16) } }, { children: [label && (_jsx(InputLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: label }))), _jsx(Select, __assign({}, otherProps, { fullWidth: true, sx: __assign({}, sx), variant: 'standard', name: name, id: id, "data-testid": id, "aria-label": name, value: defaultSelectedOption, className: 'tp-dropdown', onOpen: handleOpen, onClose: handleClose, inputProps: {
                    'data-testid': "tp-dropdown-".concat(id),
                    sx: { fontSize: fontSizeDynamic(16) },
                }, MenuProps: { disableScrollLock: true }, onChange: handleSelectChange, displayEmpty: true, IconComponent: IconComponent ||
                    (function () {
                        return selectOpen ? (_jsx(TPSvgIcon, { resourceName: 'up-arrow-icon' })) : (_jsx(TPSvgIcon, { resourceName: 'down-arrow-icon' }));
                    }), renderValue: function (selected) {
                    var _a;
                    if (selected === undefined || selected === null || selected === '') {
                        return (_jsx("span", __assign({ className: placeholderClassName, style: __assign({ color: '#686868' }, placeholderStyle) }, { children: placeholder }))); // Render placeholder when no value is selected
                    }
                    return (((_a = options.find(function (option) { return option.value === selected; })) === null || _a === void 0 ? void 0 : _a.label) || '');
                } }, { children: options.map(function (option, index) { return (_jsx(MenuItem, __assign({ value: option.value, className: 'tp-dropdown-menu', id: "tp-dropdown-menu-".concat(option.value), "data-testid": "tp-dropdown-menu-".concat(option.value) }, { children: _jsx("p", __assign({ style: { fontSize: fontSizeDynamic(16) } }, { children: option.label })) }), 'dropdown-menu-item-' + index)); }) }))] })));
};
export default TPDropdown;
