export var TANDYM_CONSTANTS = {
    API_URLS: {
        LOGIN_LINK_VALIDATION_GET: '/Auth/ValidateLoginCodeForClient',
        GENERATE_LOGIN_LINK: '/Auth/GenerateLoginCodeForClient',
        ACCEPT_TERMS_POST: '/Auth/ClientAcceptTerms',
        NAJAXA_API: '/Najaxa/GenerateNajaxaTokenClient',
        CLIENT_PLACEMENTS_GET: '/Client/GetPlacementsForClient',
        CLIENT_ENTITLEMENTS_GET: '/Client/GetEntitlementsForClient',
        CLIENT_DASHBOARD_GET: '/Dashboard/GetDashboardByClient',
        CLIENT_OPERATIONAL_METRICS_GET: '/Dashboard/GetOperationalMetricsForClient',
    },
};
