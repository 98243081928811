var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './RecruitmentRatios.scss';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import TPSvgIcon, { workIcon, submittedIcon, interviewedIcon, offeredIcon, taskIcon, } from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { ArrowContainerGridStyled, FillRateBoxStyled, RecruitmentRatiosContainer, StepsGridStyled, } from './RecruitmentRatios.styled';
var RecruitmentRatios = function (props) {
    var recruitmentRatiosData = props.recruitmentRatiosData;
    var getIcon = function (icon) {
        switch (icon) {
            case 'Positions':
                return workIcon('work-icon');
            case 'Submitted':
                return submittedIcon('submitted-icon');
            case 'Interviewed':
                return interviewedIcon('interviewed-icon');
            case 'Offered':
                return offeredIcon('offered-icon');
            case 'Accepted':
                return taskIcon('placed-icon');
            default:
                return icon;
        }
    };
    return (_jsxs(RecruitmentRatiosContainer, __assign({ className: 'recruitment-ratios-container' }, { children: [_jsxs(FillRateBoxStyled, __assign({ mt: 2, mb: 13 }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: 'caption', className: 'fill-rate-style' }, { children: "Fill Rate" })), _jsxs(Typography, __assign({ variant: 'h2', className: 'fill-rate-title' }, { children: [recruitmentRatiosData.fillRate, "%"] }))] })] })), _jsx(Box, __assign({ display: 'flex', alignItems: 'center' }, { children: _jsx(Grid, __assign({ container: true, spacing: 2, alignItems: 'center', justifyContent: 'space-between' }, { children: recruitmentRatiosData.data.map(function (step, index) { return (_jsxs(React.Fragment, { children: [_jsx(StepsGridStyled, __assign({ item: true }, { children: _jsxs(Box, __assign({ display: 'flex', flexDirection: 'column', alignItems: 'center' }, { children: [_jsx(Typography, __assign({ variant: 'subtitle1', className: 'step-icon' }, { children: getIcon(step.label) })), _jsx(Typography, __assign({ variant: 'caption', className: 'step-label' }, { children: step.label })), _jsx(Typography, __assign({ variant: 'h3', className: 'step-value' }, { children: step.value }))] })) })), index < recruitmentRatiosData.data.length - 1 && (_jsx(ArrowContainerGridStyled, __assign({ item: true }, { children: _jsxs(Box, __assign({ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative' }, { children: [recruitmentRatiosData.data[index].ratio && (_jsx(Typography, __assign({ variant: 'caption', className: 'ratio-label', fontSize: fontSizeDynamic(14) }, { children: recruitmentRatiosData.data[index].ratio }))), _jsx(TPSvgIcon, { resourceName: 'arrow-forward-icon' })] })) })))] }, 'recruitment_' + index)); }) })) }))] })));
};
export default RecruitmentRatios;
