import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
var ContractorsBilling = function (props) {
    var ContractorsOnBillingMetrics = props.ContractorsOnBillingMetrics, onDrillDownData = props.onDrillDownData, setOpenDrillDown = props.setOpenDrillDown;
    var data = ContractorsOnBillingMetrics
        ? ContractorsOnBillingMetrics.data
        : [];
    var handleClick = function (e) {
        var getDrillDownData = data[e.point.index].drillDownData;
        setOpenDrillDown(true);
        onDrillDownData(getDrillDownData);
    };
    var chartOptions = {
        chart: {
            type: 'area',
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        xAxis: {
            categories: data.map(function (item) { return item.label; }),
            tickmarkPlacement: 'on',
            title: {
                text: '',
            },
        },
        yAxis: {
            title: {
                text: 'Sum of KPICount',
            },
        },
        tooltip: {
            shared: true,
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: handleClick,
                    },
                },
            },
            area: {
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true,
                        },
                    },
                },
            },
        },
        series: [
            {
                type: 'area',
                name: 'KPI Count',
                data: data.map(function (item) { return item.value; }),
                lineWidth: 3,
                color: '#2A70EA',
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, 'rgba(190, 227, 248, 0.75)'],
                        [1, 'rgba(190, 227, 248, 0)'],
                    ],
                },
            },
        ],
    };
    return (_jsx(_Fragment, { children: _jsx(HighchartsReact, { highcharts: Highcharts, options: chartOptions }) }));
};
export default ContractorsBilling;
