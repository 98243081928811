import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// WaterfallChart.tsx
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import the Highcharts More module
import HighchartsExporting from 'highcharts/modules/exporting'; // Optional: for exporting charts
import HighchartsExportData from 'highcharts/modules/export-data'; // Optional: for exporting data
// Initialize the modules
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
var RecruitmentTimeline = function (props) {
    var recruitmentTimelineData = props.recruitmentTimelineData;
    var graphBarColor = function (value) {
        if (value < 0) {
            return '#E31A1C';
        }
        else {
            return '#2b6fea';
        }
    };
    var options = {
        chart: {
            type: 'waterfall',
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false, // Removes the Highcharts watermark
        },
        legend: {
            enabled: false, // Hides the legend
        },
        exporting: {
            enabled: false, // Disable the export menu (hamburger menu)
        },
        xAxis: {
            categories: recruitmentTimelineData.map(function (item) { return item.label; }),
            title: {
                text: null,
            },
        },
        yAxis: {
            title: {
                text: '# of Days',
            },
        },
        tooltip: {
            useHTML: true,
            backgroundColor: '#f7f7f7',
            borderColor: '#aaa',
            borderRadius: 5,
            style: {
                color: '#333', // Custom text color
            },
            shadow: true, // Adds shadow to tooltip for better visibility
        },
        plotOptions: {
            waterfall: {
                borderRadius: 0,
                borderWidth: 0, // Removes the border color
            },
            series: {
                dataLabels: {
                    enabled: true,
                    // useHTML: true,
                },
            },
        },
        series: [
            {
                type: 'waterfall',
                name: 'Data',
                data: recruitmentTimelineData.map(function (item) { return ({
                    name: item.label,
                    y: item.value,
                    isSum: item.label === 'Total',
                    color: item.label === 'Total' ? 'black' : graphBarColor(item.value),
                }); }),
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                },
            },
        ],
    };
    return (_jsx(_Fragment, { children: _jsx(HighchartsReact, { highcharts: Highcharts, options: options }) }));
};
export default RecruitmentTimeline;
